import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Session 1`}</h2>
    <p>{`We begin with `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen Quint`}</a>{`. She has brought `}<a parentName="p" {...{
        "href": "/The%20Sixth%20Tinwheel",
        "title": "The Sixth Tinwheel"
      }}>{`The Sixth Tinwheel`}</a>{`, a `}<a parentName="p" {...{
        "href": "/Tinwheel",
        "title": "Tinwheel"
      }}>{`Tinwheel`}</a>{` that her father, `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{` created just before his sudden disappearance, to the `}<a parentName="p" {...{
        "href": "/Saetorim%20Institute",
        "title": "Saetorim Institute"
      }}>{`Saetorim Institute`}</a>{`. She has hired the founder of the Institute, `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin Fane`}</a>{` to find her father and shed light on the mystery of his supposed kidnapping.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/4fee9cb4364d8406a0d6bdbc49b7ed4d/aa440/Morne_A_man_holding_a_small_phonograph_cylinder_made_of_pure_si_f1e59b83-8eec-4332-9bb6-91c66fd052e9.png",
            "srcSet": ["/static/4fee9cb4364d8406a0d6bdbc49b7ed4d/5ff7e/Morne_A_man_holding_a_small_phonograph_cylinder_made_of_pure_si_f1e59b83-8eec-4332-9bb6-91c66fd052e9.png 375w", "/static/4fee9cb4364d8406a0d6bdbc49b7ed4d/1d69c/Morne_A_man_holding_a_small_phonograph_cylinder_made_of_pure_si_f1e59b83-8eec-4332-9bb6-91c66fd052e9.png 750w", "/static/4fee9cb4364d8406a0d6bdbc49b7ed4d/aa440/Morne_A_man_holding_a_small_phonograph_cylinder_made_of_pure_si_f1e59b83-8eec-4332-9bb6-91c66fd052e9.png 1500w", "/static/4fee9cb4364d8406a0d6bdbc49b7ed4d/71c1d/Morne_A_man_holding_a_small_phonograph_cylinder_made_of_pure_si_f1e59b83-8eec-4332-9bb6-91c66fd052e9.png 1536w"],
            "width": "500px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Corrin examines a tinwheel`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{``}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin Fane`}</a>{` enlists the help of `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo Livingston`}</a>{`, a new hire to the Institute, `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian Cullpepper`}</a>{`, a professor at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` who was a close colleague of Enzo's, and `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sulazar Stillwater`}</a>{`, an old partner of Corrin's who has familiarity with the `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Msanti`}</a>{` down in the `}<a parentName="p" {...{
        "href": "/Bathysward",
        "title": "Bathysward"
      }}>{`Bathysward`}</a>{`.`}</p>
    <p>{`This intrepid and equally unlikely crew decide to head to `}<a parentName="p" {...{
        "href": "/Ari%20Vochard",
        "title": "Ari Vochard"
      }}>{`Ari Vochard's`}</a>{` house, Eileen's other father. There, as they discuss their plan, a `}<a parentName="p" {...{
        "href": "/Protoxy",
        "title": "Protoxy"
      }}>{`Protoxy`}</a>{` from the `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`Argentia`}</a>{` showed up. `}<a parentName="p" {...{
        "href": "/Emere%20Renguard",
        "title": "Emere Renguard"
      }}>{`Emere Renguard`}</a>{` brought Eileen's brother, `}<a parentName="p" {...{
        "href": "/Vytas%20Quint",
        "title": "Vytas Quint"
      }}>{`Vytas Quint`}</a>{`, back from the steps of the `}<a parentName="p" {...{
        "href": "/Pal%20Tenach",
        "title": "Pal Tenach"
      }}>{`Pal Tenach`}</a>{`, higher than the Luminary on none other than `}<a parentName="p" {...{
        "href": "/Lotus",
        "title": "Lotus"
      }}>{`Lotus`}</a>{`, a new drug that has been circulating the streets of `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`. Emere warns them that Vytas is on his last straw, and will be disavowed of his position in the Argentia if he does not get it together. He also notifies the crew that the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{` are currently on their way to Enzo's house to begin their investigation into his disappearance (clearly Vytas must have slipped the lid, as Eileen and Ari were trying to go through the Institute to prevent the "official" authorities from getting involved).`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "left center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/f35f4b2587c5a2e4fd7aebae5397e12d/2bef9/Morne_Young_man_with_white_hair_laying_on_the_ground_drunk_wear_0a9487dc-3b99-4a5e-b0c1-377c0348d4bb.png",
            "srcSet": ["/static/f35f4b2587c5a2e4fd7aebae5397e12d/5ff7e/Morne_Young_man_with_white_hair_laying_on_the_ground_drunk_wear_0a9487dc-3b99-4a5e-b0c1-377c0348d4bb.png 375w", "/static/f35f4b2587c5a2e4fd7aebae5397e12d/1d69c/Morne_Young_man_with_white_hair_laying_on_the_ground_drunk_wear_0a9487dc-3b99-4a5e-b0c1-377c0348d4bb.png 750w", "/static/f35f4b2587c5a2e4fd7aebae5397e12d/2bef9/Morne_Young_man_with_white_hair_laying_on_the_ground_drunk_wear_0a9487dc-3b99-4a5e-b0c1-377c0348d4bb.png 1024w"],
            "width": "300px",
            "className": "left center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Vytas recovering from Lotus`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Vytas, in his stupor, also talks to Eileen about getting more money from their father, specifically talking about Enzo. This sparks the idea that perhaps Enzo had left some clues as to what he was doing in his safety deposit box at the bank.`}</p>
    <p>{`The crew decides to head to the `}<a parentName="p" {...{
        "href": "/Central%20Bank%20of%20Meripol",
        "title": "Central Bank of Meripol"
      }}>{`Central Bank of Meripol`}</a>{` to check it out, as Vytas is able to give Eileen a key. On the way, they stop by Enzo's house, hoping to beat the Vigil there. Unfortunately, a group of detectives has already set up a crime scene and has begun going through all of Enzo's things, looking for clues.`}</p>
    <p>{``}<a parentName="p" {...{
        "href": "/Estelle%20Sartere",
        "title": "Estelle Sartere"
      }}>{`Estelle Sartere`}</a>{` notices `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo`}</a>{` walking past, and stalks over to him, loosely accusing him of scoping out his own crime scene, insinuating towards his criminal endeavors. The conversation does not go well, but Eileen is able to talk to her instead, and gleans that the Vigil have in fact discovered and taken possession of `}<a parentName="p" {...{
        "href": "/The%20Second%20Tinwheel",
        "title": "The Second Tinwheel"
      }}>{`The Second Tinwheel`}</a>{` (though it is not known which it is at the time).`}</p>
    <p>{`With nothing to do about it in the moment, they decide to head to towards the bank. Arlo tells the group he may have a way of getting the Tinwheel from the Vigil, and separates from the group to meet with his contact in the criminal underworld, `}<a parentName="p" {...{
        "href": "/Jax",
        "title": "Jax"
      }}>{`Jax`}</a>{`. `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sully`}</a>{` heads to a food vendor while `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian`}</a>{` and `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen`}</a>{` head into the bank. While waiting in line, `}<a parentName="p" {...{
        "href": "/Roz%20Daras",
        "title": "Roz Daras"
      }}>{`Roz Daras`}</a>{` enters the bank and begins a bank robbery.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/b0d94e48a1009e41b20cb72ccff9dc6d/aa440/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png",
            "srcSet": ["/static/b0d94e48a1009e41b20cb72ccff9dc6d/5ff7e/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png 375w", "/static/b0d94e48a1009e41b20cb72ccff9dc6d/1d69c/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png 750w", "/static/b0d94e48a1009e41b20cb72ccff9dc6d/aa440/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png 1500w", "/static/b0d94e48a1009e41b20cb72ccff9dc6d/71c1d/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png 1536w"],
            "width": "500px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Roz with his energy shotgun`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`While waiting for food, `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sully`}</a>{` receives a call from `}<a parentName="p" {...{
        "href": "/Henry%20Wulverstone",
        "title": "Henry Wulverstone"
      }}>{`Henry Wulverstone`}</a>{` that he has a job waiting for him back in the `}<a parentName="p" {...{
        "href": "/Bathysward",
        "title": "Bathysward"
      }}>{`Bathysward`}</a>{`.`}</p>
    <h2>{`Session 2`}</h2>
    <p>{`Arlo meets with `}<a parentName="p" {...{
        "href": "/Jax",
        "title": "Jax"
      }}>{`Jax`}</a>{`, and asks if he can procure evidence from lockups at the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{`. `}<a parentName="p" {...{
        "href": "/Jax",
        "title": "Jax"
      }}>{`Jax`}</a>{` says he can do it, but in return Arlo must go to a `}<a parentName="p" {...{
        "href": "/Sonxai",
        "title": "Sonxai"
      }}>{`Sonxai`}</a>{` warehouse and attempt to steal any information they have on how `}<a parentName="p" {...{
        "href": "/Lotus",
        "title": "Lotus"
      }}>{`Lotus`}</a>{` is produced. Arlo agrees, and the two part ways.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "left center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/b4d1eb39094ca050d56d918bdd86962d/2bef9/Morne_a_young_man_wearing_a_turtle_neck_and_a_long_blue_coat_st_703294ce-f2e5-4ce6-909d-66de6073918a.png",
            "srcSet": ["/static/b4d1eb39094ca050d56d918bdd86962d/5ff7e/Morne_a_young_man_wearing_a_turtle_neck_and_a_long_blue_coat_st_703294ce-f2e5-4ce6-909d-66de6073918a.png 375w", "/static/b4d1eb39094ca050d56d918bdd86962d/1d69c/Morne_a_young_man_wearing_a_turtle_neck_and_a_long_blue_coat_st_703294ce-f2e5-4ce6-909d-66de6073918a.png 750w", "/static/b4d1eb39094ca050d56d918bdd86962d/2bef9/Morne_a_young_man_wearing_a_turtle_neck_and_a_long_blue_coat_st_703294ce-f2e5-4ce6-909d-66de6073918a.png 1024w"],
            "width": "350px",
            "className": "left center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Arlo meeting with Jax`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Meanwhile, Eileen and Lucian deal with a hostage situation. `}<a parentName="p" {...{
        "href": "/Roz%20Daras",
        "title": "Roz Daras"
      }}>{`Roz Daras`}</a>{` takes `}<a parentName="p" {...{
        "href": "/Gerod%20Farrault",
        "title": "Gerod Farrault"
      }}>{`Gerod Farrault`}</a>{`, the Bank's manager, hostage, and orders him down into the vaults. `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sully`}</a>{` sneaks into the Bank from an alleyway, grabbing a piece of Rebar (that's narratively significant Rebar!). Inside, he meets up with Lucian and Eileen, and the three of them decide to go down into the vaults and attempt to stop the robbery.`}</p>
    <p>{`The discover that the robbers are after Enzo's vault as well, though it is unclear whether that is of Roz's own accord, or if he was hired by someone. Sully manages to knock Roz unconscious with the Rebar, saving the day. The three of them convince Gerod to let them open Enzo's box and flee with the contents before the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{` could arrive.`}</p>
    <p>{`They make their way back to the institute and reconvene with `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin`}</a>{`.`}</p>
    <h2>{`Session 3`}</h2>
    <p>{`The contents of Enzo's safe included `}<a parentName="p" {...{
        "href": "/The%20First%20Tinwheel",
        "title": "The First Tinwheel"
      }}>{`The First Tinwheel`}</a>{` and a number of purchase documents.`}</p>
    <p>{`The crew listens to the Tinwheel with Corrin, and learn that `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo`}</a>{` was looking in to some historical records detailing the `}<a parentName="p" {...{
        "href": "/The%20Church%20of%20Aphothis",
        "title": "The Church of Aphothis"
      }}>{`The Church of Aphothis`}</a>{`, a cult that operated in the ruin city of `}<a parentName="p" {...{
        "href": "/Mehnateruna",
        "title": "Mehnateruna"
      }}>{`Mehnateruna`}</a>{` many centuries ago. The cult supposedly enacted sacrifices that involved severing the `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{` of their victims. Enzo postulates that perhaps the cult was somehow utilizing some power generated by the severing process.`}</p>
    <p>{`The documents show that `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo`}</a>{` was receiving money from `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{` of the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family`}</a>{`, one of `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudum's`}</a>{` greatest `}<a parentName="p" {...{
        "href": "/Aljiedum%20Noble%20Houses",
        "title": "Aljiedum Noble Houses"
      }}>{`Noble Houses`}</a>{`. `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart`}</a>{` himself, however, has been somewhat of a recluse for the last twenty years, as noted by `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin`}</a>{`. Enzo was also `}<em parentName="p">{`making`}</em>{` purchases to a corporation called Crimson, which Sully recognizes as a different name used for the `}<a parentName="p" {...{
        "href": "/Sonxai",
        "title": "Sonxai"
      }}>{`Sonxai`}</a>{`. Enzo also had purchase orders for goods transported from the town of `}<a parentName="p" {...{
        "href": "/Gris",
        "title": "Gris"
      }}>{`Gris`}</a>{` by the `}<a parentName="p" {...{
        "href": "/Prisma%20Travel%20Corporation",
        "title": "Prisma Travel Corporation"
      }}>{`Prisma Travel Corporation`}</a>{`.`}</p>
    <p>{`The team decides to go home for the day, and `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sully`}</a>{` heads back to the `}<a parentName="p" {...{
        "href": "/Bathysward",
        "title": "Bathysward"
      }}>{`Bathysward`}</a>{` to take care of his job. He meets up with his assistant, `}<a parentName="p" {...{
        "href": "/Henry%20Wulverstone",
        "title": "Henry Wulverstone"
      }}>{`Henry Wulverstone`}</a>{`, who informs him that one of a few bathysphere's that had gone missing in the Ward a few months ago was discovered as wreckage a few miles offshore, and that Sully has been tasked with investigating.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/b81c9be3f87be25003be26b9a88547f1/2bef9/Morne_a_posessed_man_in_an_engineers_uniform_with_pure_black_ey_c84bef5b-a514-42c6-9509-88ed4c8a6139.png",
            "srcSet": ["/static/b81c9be3f87be25003be26b9a88547f1/5ff7e/Morne_a_posessed_man_in_an_engineers_uniform_with_pure_black_ey_c84bef5b-a514-42c6-9509-88ed4c8a6139.png 375w", "/static/b81c9be3f87be25003be26b9a88547f1/1d69c/Morne_a_posessed_man_in_an_engineers_uniform_with_pure_black_ey_c84bef5b-a514-42c6-9509-88ed4c8a6139.png 750w", "/static/b81c9be3f87be25003be26b9a88547f1/2bef9/Morne_a_posessed_man_in_an_engineers_uniform_with_pure_black_ey_c84bef5b-a514-42c6-9509-88ed4c8a6139.png 1024w"],
            "width": "350px",
            "className": "right center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`The possessed engineer amidst bathyscape wreckage`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Sully goes alone, taking a small single-personnel bathysphere out to the wreckage and investigates. He finds... nothing at first. Nothing in Engineering seems out of place, but there also seem to be no crew about. He heads to the bridge, needing to break out his welding equipment to do so. Inside, he discovers a shadowy figure, wearing an engineer's uniform standing atop a pile of bodies, all of their eyes removed, including the figure's. The figure calls out to Sully, who runs. The figure gives chase, but Sully manages to escape in his bathysphere and abandon the wreckage.`}</p>
    <p>{`When he returns, he tells `}<a parentName="p" {...{
        "href": "/Henry%20Wulverstone",
        "title": "Henry Wulverstone"
      }}>{`Henry`}</a>{` to call `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{`, then leaves, heading to `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin's`}</a>{`.`}</p>
    <h2>{`Session 4`}</h2>
    <p>{`Arlo meets with `}<a parentName="p" {...{
        "href": "/Jax",
        "title": "Jax"
      }}>{`Jax`}</a>{` for a second time, retrieving the `}<a parentName="p" {...{
        "href": "/The%20Second%20Tinwheel",
        "title": "The Second Tinwheel"
      }}>{`The Second Tinwheel`}</a>{`. Jax tells Arlo that he must complete the job regarding the `}<a parentName="p" {...{
        "href": "/Sonxai",
        "title": "Sonxai"
      }}>{`Sonxai`}</a>{` warehouse and `}<a parentName="p" {...{
        "href": "/Lotus",
        "title": "Lotus"
      }}>{`Lotus`}</a>{` today, because they got wind of something and are currently in the process of going dark.`}</p>
    <p>{`Arlo heads to the warehouse and sneaks in through the roof, discovering a warehouse full of row upon row of Lotus flowers, along with a `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Msanti`}</a>{` tasked with guarding the warehouse. Arlo manages to give the Deep Dweller the slip using his `}<a parentName="p" {...{
        "href": "/Primal%20Aspects#space",
        "title": "Primal Aspects"
      }}>{`Space`}</a>{` magic, locking himself in a small office-like room in the back of the warehouse. There, he discovers a bathymetric map of the ocean outside of `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`, a spot far out into the ocean conspicuously marked, though there is no indication as to what it is. Arlo again uses the Aspect of Space to escape the warehouse.`}</p>
    <p>{`The crew reconvenes at the `}<a parentName="p" {...{
        "href": "/Saetorim%20Institute",
        "title": "Saetorim Institute"
      }}>{`Saetorim Institute`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin`}</a>{` tells them that he has found a way to get them into one of the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family's`}</a>{` galas as serving staff, which would allow them to snoop around the place.`}</p>
    <p>{`A few days later, the crew head to the Gala. `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sully`}</a>{`, `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo`}</a>{` attend posing as waitstaff, but `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Luci`}</a>{` managed to get his own invitation through `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "left center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/979d9b7883698f183a944754d6983d3d/2bef9/Morne_A_man_sits_on_a_stool_back_turned_painting_on_an_easel_S_fa796363-32e9-4381-badd-6f13d669713b.png",
            "srcSet": ["/static/979d9b7883698f183a944754d6983d3d/5ff7e/Morne_A_man_sits_on_a_stool_back_turned_painting_on_an_easel_S_fa796363-32e9-4381-badd-6f13d669713b.png 375w", "/static/979d9b7883698f183a944754d6983d3d/1d69c/Morne_A_man_sits_on_a_stool_back_turned_painting_on_an_easel_S_fa796363-32e9-4381-badd-6f13d669713b.png 750w", "/static/979d9b7883698f183a944754d6983d3d/2bef9/Morne_A_man_sits_on_a_stool_back_turned_painting_on_an_easel_S_fa796363-32e9-4381-badd-6f13d669713b.png 1024w"],
            "width": "400px",
            "className": "left center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Elijah painting`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`During the commotion of the party, Arlo sneaks off and discovers a small courtyard with numerous plants. A man is in the middle of the courtyard, painting on the canvas. As Arlo approaches, he notices what the man is painting. A hollowed out eye in the clutches of black tendrils. The man notices Arlo, and turns to run.`}</p>
    <h2>{`Session 5`}</h2>
    <p>{`Arlo runs after the man, and catches up to him. When the man turns, Arlo notices that he is now a different person. Realization dawns that the man must be a `}<a parentName="p" {...{
        "href": "/Shifter",
        "title": "Shifter"
      }}>{`Shifter`}</a>{`. He befriends the man, whose name is `}<a parentName="p" {...{
        "href": "/Elijah",
        "title": "Elijah"
      }}>{`Elijah`}</a>{`. Elijah admits that he has been hired by the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family`}</a>{` for the last fifteen years to pose as `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{`.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/b0a5bdc428eafd6cf6d828429171f84b/aa440/Morne_steampunk_fantasy_gala_lively_party_interior_of_a_mansion_dfa25a33-bb8d-4e31-895d-d4f0410b9dd5.png",
            "srcSet": ["/static/b0a5bdc428eafd6cf6d828429171f84b/5ff7e/Morne_steampunk_fantasy_gala_lively_party_interior_of_a_mansion_dfa25a33-bb8d-4e31-895d-d4f0410b9dd5.png 375w", "/static/b0a5bdc428eafd6cf6d828429171f84b/1d69c/Morne_steampunk_fantasy_gala_lively_party_interior_of_a_mansion_dfa25a33-bb8d-4e31-895d-d4f0410b9dd5.png 750w", "/static/b0a5bdc428eafd6cf6d828429171f84b/aa440/Morne_steampunk_fantasy_gala_lively_party_interior_of_a_mansion_dfa25a33-bb8d-4e31-895d-d4f0410b9dd5.png 1500w", "/static/b0a5bdc428eafd6cf6d828429171f84b/71c1d/Morne_steampunk_fantasy_gala_lively_party_interior_of_a_mansion_dfa25a33-bb8d-4e31-895d-d4f0410b9dd5.png 1536w"],
            "width": "500px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`The Lauten Gala`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Back at the party, Eileen meets `}<a parentName="p" {...{
        "href": "/Vera%20Lauten",
        "title": "Vera Lauten"
      }}>{`Vera Lauten`}</a>{`. Shortly after, Speaker of the `}<a parentName="p" {...{
        "href": "/High%20Authority",
        "title": "High Authority"
      }}>{`High Authority`}</a>{`, `}<a parentName="p" {...{
        "href": "/Rysha%20Tan%20Mei",
        "title": "Rysha Tan Mei"
      }}>{`Rysha Tan Mei`}</a>{` shows up unexpectedly. She finds a place for her and her entourage and begins questioning anyone who ventures close. Lucian approaches and she beckons him over. They chat, and he learns that she is also investigating the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family`}</a>{`. She says that she met `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo`}</a>{` briefly when he came to `}<a parentName="p" {...{
        "href": "/Mehnateruna",
        "title": "Mehnateruna"
      }}>{`Mehnateruna`}</a>{` (during `}<a parentName="p" {...{
        "href": "/The%20Second%20Tinwheel",
        "title": "The Second Tinwheel"
      }}>{`The Second Tinwheel`}</a>{`) and that she wants to get to the bottom of what's going on. She tells Luci that she was there twenty years ago when `}<a parentName="p" {...{
        "href": "/Adam%20La%20Cour",
        "title": "Adam La Cour"
      }}>{`Adam La Cour`}</a>{` took students, including her and `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{`, to the city, where they accidentally enacted a ritual in the `}<a parentName="p" {...{
        "href": "/Spirit%20Sanctum",
        "title": "Spirit Sanctum"
      }}>{`Spirit Sanctum`}</a>{` which caused Rikkart to lose his `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{`.`}</p>
    <p>{`She tells Luci that she will be in touch, and wants to help in whatever way she can.`}</p>
    <p>{``}<a parentName="p" {...{
        "href": "/Elijah",
        "title": "Elijah"
      }}>{`Elijah`}</a>{` shows `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo`}</a>{` Rikkart's old room. Elijah notes that the room has been untouched since he started posing as Rikkart, none of the staff will go into the room, claiming that it is haunted. Inside the room, `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo`}</a>{` discovers a journal, supposedly written by the real `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart`}</a>{`. The journal seems to document Rikkart's descent into madness after having his thread severed in `}<a parentName="p" {...{
        "href": "/Mehnateruna",
        "title": "Mehnateruna"
      }}>{`Mehnateruna`}</a>{`. The last words of the journal are "There is no light that I can see, down in the Dark Between."`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "left center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/7980c2065d387eb89dee10c6a54f5ca9/aa440/Morne_Setting_is_the_interior_of_a_steampunk_train_car_b5e7077c-4f2e-41cf-8cab-cbd135727c7f.png",
            "srcSet": ["/static/7980c2065d387eb89dee10c6a54f5ca9/5ff7e/Morne_Setting_is_the_interior_of_a_steampunk_train_car_b5e7077c-4f2e-41cf-8cab-cbd135727c7f.png 375w", "/static/7980c2065d387eb89dee10c6a54f5ca9/1d69c/Morne_Setting_is_the_interior_of_a_steampunk_train_car_b5e7077c-4f2e-41cf-8cab-cbd135727c7f.png 750w", "/static/7980c2065d387eb89dee10c6a54f5ca9/aa440/Morne_Setting_is_the_interior_of_a_steampunk_train_car_b5e7077c-4f2e-41cf-8cab-cbd135727c7f.png 1500w", "/static/7980c2065d387eb89dee10c6a54f5ca9/71c1d/Morne_Setting_is_the_interior_of_a_steampunk_train_car_b5e7077c-4f2e-41cf-8cab-cbd135727c7f.png 1536w"],
            "width": "500px",
            "className": "left center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Creature on the train`}</div>{`
                `}</div>{`
                `}</undefined>{`
The party wraps up, and the crew regroups on the way back to the train station to the city. They get on the train, discussing everything that they had learned, when further up in the train car a mysterious woman wearing a cloak attacks a man, placing a device on his chest. The device causes him to collapse to the ground. From it, a pool of black dripping smoke emerges, coalescing into the form of a large slug-like creature with spikes.`}</p>
    <h2>{`Session 6`}</h2>
    <p>{`The mysterious women disappears through the front of the train car. The crew is attacked by the slug monster and is overwhelmed. `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo`}</a>{` attempts to escape towards the back of the train, but one of the creature's tendrils grasps on to him before he gets a chance. The creature's thrashing threatens to disrupt the train and send it careening a hundred feet from the tracks to the icy ravine below.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/7196a5982e8d0d12d4c8a9ec1ab1c353/aa440/Morne_A_large_hawk_made_of_fire_epic_fantasy_art_28cdb5ff-023d-432e-b2e8-8751a18b1377.png",
            "srcSet": ["/static/7196a5982e8d0d12d4c8a9ec1ab1c353/5ff7e/Morne_A_large_hawk_made_of_fire_epic_fantasy_art_28cdb5ff-023d-432e-b2e8-8751a18b1377.png 375w", "/static/7196a5982e8d0d12d4c8a9ec1ab1c353/1d69c/Morne_A_large_hawk_made_of_fire_epic_fantasy_art_28cdb5ff-023d-432e-b2e8-8751a18b1377.png 750w", "/static/7196a5982e8d0d12d4c8a9ec1ab1c353/aa440/Morne_A_large_hawk_made_of_fire_epic_fantasy_art_28cdb5ff-023d-432e-b2e8-8751a18b1377.png 1500w", "/static/7196a5982e8d0d12d4c8a9ec1ab1c353/71c1d/Morne_A_large_hawk_made_of_fire_epic_fantasy_art_28cdb5ff-023d-432e-b2e8-8751a18b1377.png 1536w"],
            "width": "500px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Firehawk`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`The team is overwhelmed by the creature's attacks, and `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sully`}</a>{` is knocked unconscious by the creature, until `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian`}</a>{` creates a barricade using `}<a parentName="p" {...{
        "href": "/Primal%20Aspects#material",
        "title": "Primal Aspects"
      }}>{`Material`}</a>{`, and uses `}<a parentName="p" {...{
        "href": "/Primal%20Aspects#gravity",
        "title": "Primal Aspects"
      }}>{`Gravity`}</a>{` to launch the creature at the ceiling. `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen`}</a>{` uses `}<a parentName="p" {...{
        "href": "/Primal%20Aspects#shape",
        "title": "Primal Aspects"
      }}>{`Shape`}</a>{` to shift into a `}<a parentName="p" {...{
        "href": "/Firehawk",
        "title": "Firehawk"
      }}>{`Firehawk`}</a>{`, and she launches flames at the creature, causing its sludge-like body to catch fire. Together, the crew defeats the creature, and its body begins to dissolve into a black ichor, evaporating into the air. Before it completely disappears, `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian`}</a>{` manages to bottle a small bit of the ichor. `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo`}</a>{` takes the strange brass device from the victim's chest.`}</p>
    <p>{`The train slows to a stop, and `}<a parentName="p" {...{
        "href": "/Beron",
        "title": "Beron"
      }}>{`Beron`}</a>{`, the conductor of the `}<a parentName="p" {...{
        "href": "/Grav-rail",
        "title": "Grav-rail"
      }}>{`Grav-rail`}</a>{`, bursts into the rear car, demanding to know what is going on. The crew explain, and after seeing the dead body, Beron is understandably upset at the prospect of filling out a report for this incident. He ushers the crew towards the front of the train, asking them to stick around once they return to the station to be questioned by the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{`. They do, except `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sully`}</a>{` hides the device and the ichor away and steals from the train before the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{` arrive.`}</p>
    <p>{`None other than `}<a parentName="p" {...{
        "href": "/Estelle%20Sartere",
        "title": "Estelle Sartere"
      }}>{`Estelle Sartere`}</a>{` shows up with two other members of the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{`. She is surprised, and notes that she was actually on the way to the `}<a parentName="p" {...{
        "href": "/Saetorim%20Institute",
        "title": "Saetorim Institute"
      }}>{`Saetorim Institute`}</a>{` to speak with them when she got the call to investigate an incident at the train station. `}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "left center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/24e321368736ee1d0c1d68e72bfb63a0/aa440/Morne_three_detective_soldiers_in_silver_uniforms_standing_in_a_0fd74961-b869-40a2-bceb-6fdbd86a7504.png",
            "srcSet": ["/static/24e321368736ee1d0c1d68e72bfb63a0/5ff7e/Morne_three_detective_soldiers_in_silver_uniforms_standing_in_a_0fd74961-b869-40a2-bceb-6fdbd86a7504.png 375w", "/static/24e321368736ee1d0c1d68e72bfb63a0/1d69c/Morne_three_detective_soldiers_in_silver_uniforms_standing_in_a_0fd74961-b869-40a2-bceb-6fdbd86a7504.png 750w", "/static/24e321368736ee1d0c1d68e72bfb63a0/aa440/Morne_three_detective_soldiers_in_silver_uniforms_standing_in_a_0fd74961-b869-40a2-bceb-6fdbd86a7504.png 1500w", "/static/24e321368736ee1d0c1d68e72bfb63a0/71c1d/Morne_three_detective_soldiers_in_silver_uniforms_standing_in_a_0fd74961-b869-40a2-bceb-6fdbd86a7504.png 1536w"],
            "width": "500px",
            "className": "left center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Estelle Sartere and Vigilant Detectives`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`She escorts the crew back to the institute, and questions `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sully`}</a>{` on the events that took place in the bathyscaphe wreckage. She tells them that they should stop meddling in the investigation, and to let the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{` handle it. The crew deduces that there is more than she is letting on, and after some convincing it is revealed that her niece, a scion of the `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Soul",
        "title": "Primal Spirit of Soul"
      }}>{`Primal Spirit of Soul`}</a>{`, along with many other `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` in the city, have gone missing over the last few weeks. She believes it to be related to what the Institute has been investigating. They convince Estelle to trust them to continue their investigation so long as they keep her up-to-date, and a mutual partnership is opened between the crew and the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{`.`}</p>
    <p>{`After the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{` leave, the crew discuss with `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin`}</a>{` their discoveries. It is decided that the next course of action is to investigate the location out in the `}<a parentName="p" {...{
        "href": "/Sejun%20Sea",
        "title": "Sejun Sea"
      }}>{`Sejun Sea`}</a>{`, marked on the map retrieved from the `}<a parentName="p" {...{
        "href": "/Sonxai",
        "title": "Sonxai"
      }}>{`Sonxai`}</a>{` `}<a parentName="p" {...{
        "href": "/Lotus",
        "title": "Lotus"
      }}>{`Lotus`}</a>{` warehouse.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      